import ArchiveProjectsList from "@/model/ArchiveProjectsList";
import { ActionContext } from "vuex";
import { Interval, CameraSettings, CurrentRowOpen } from "@/interface/projectsInterface";
import { AppState } from "@/stores";
import { ProjectStoreClass } from "./index";
import { AxiosError, AxiosResponse } from "axios";
import {
  Projects,
  SceneProject,
  ProjectSectionDetails,
  ProjectSectionOverride,
  ProjectSectionDisplays,
  CountryService
} from "@/services/projectService";
import router from "@/router";
import Project, { ProjectThreshold, TrafficCountStats } from "@/model/Project";
import ibomadeUserService from "@/services/ibomadeUserService";
import Section from "@/model/ModelSection";
import SectionState from "@/model/ModelSectionState";
import { TrafficCountDetails } from "@/services/evaluatorService";

interface ResponseError {
  message: string;
  label?: string;
}

export default {
  async GetAllProjectsFromBackEnd({ commit, rootGetters }: ActionContext<ProjectStoreClass, AppState>) {
    try {
      let res;
      if (rootGetters.isUserAdmin || rootGetters.isUserProjectManager || rootGetters.isConstructionManager) {
        res = await Projects.allProjects();
      } else {
        res = await Projects.allProjectsForUser((router as any).app.$keycloak.tokenParsed.sub);
      }
      if (res.status === 200) {
        commit("SetProjectList", res.data.projectList);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },

  async GetAllActiveProjects({ commit, rootGetters }: ActionContext<ProjectStoreClass, AppState>) {

    try {
      let res;

      if(rootGetters.isUserAdmin) {
        res = await Projects.getAllActiveProjectsWithSections();
      } else {
        res = await Projects.allProjectsForUser((router as any).app.$keycloak.tokenParsed.sub);
      }
      if (res.status === 200) {
        commit("SetProjectList", res.data.projectList);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }



  },

  async GetAllArchivedProjectsFromBackEnd({ commit }: ActionContext<ProjectStoreClass, AppState>) {
    try {
      const res = await Projects.allArchivedProjects();
      if (res.status === 200) {
        const formattedList = new ArchiveProjectsList(res.data);
        commit("SetArchivedProjectsList", formattedList);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  // Project Types
  async GetAllTypesFromBackEnd({ commit }: ActionContext<ProjectStoreClass, AppState>) {
    try {
      const res = await Projects.allProjectTypes();
      commit("SetProjectTypes", res);
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  // projectStatus
  async GetAllStatusFromBackEnd({ commit }: ActionContext<ProjectStoreClass, AppState>) {
    try {
      const res = await Projects.allStatus();
      //   let noArchived = res.filter((item: ProjectStatus) => item.name !== "archived");
      commit("SetStatusList", res);
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async CreateNewProject({ commit, dispatch }: ActionContext<ProjectStoreClass, AppState>, newProject: Project) {
    try {
      const res = await Projects.CreateNewProject(newProject);
      if (res.status === 201) {
        dispatch("GetAllProjectsFromBackEnd");
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async DetailsOfProject({ commit, rootGetters }: ActionContext<ProjectStoreClass, AppState>, projectId: string) {
    try {
      const res = await Projects.DetailsProject(projectId);
      if (res.status === 200) {
        const incomingProject = new Project(res.data);
        const canViewProjectLeaderInformation = rootGetters.getUserAccess.Access.VIEW.project.leaderInformation;

        incomingProject.updateStatus();
        canViewProjectLeaderInformation ? incomingProject.getLeaderInformation() : "";

        commit("setDetailsOfProject", incomingProject);
        commit("setCanViewProjectDetails", true);

        const response = await SceneProject.GetAllScenesInTheProject(projectId);
        if (response.status === 200) {
          commit("SetProjectScenes", response.data.scenes);
        }

        return res;
      } else if (res.status === 403) {
        commit("setCanViewProjectDetails", false);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      commit("setCanViewProjectDetails", false);
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data;
    }
  },
  async UpdateCurrentProject({ commit }: ActionContext<ProjectStoreClass, AppState>, projectToUpdate: Project) {
    try {
      const res = await Projects.UpdateCurrentProject(projectToUpdate);
      const incomingProject = new Project(res.data);
      incomingProject.updateStatus();
      commit("setDetailsOfProject", incomingProject);
      return res;
    } catch (error) {
      return error;
    }
  },
  async UpdateProject({ dispatch }: ActionContext<ProjectStoreClass, AppState>, projectToUpdate: Project) {
    try {
      const res = await Projects.UpdateCurrentProject(projectToUpdate);
      if (res.status === 200) {
        await dispatch("GetAllProjectsFromBackEnd");
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },
  async restoreProject({ dispatch }: ActionContext<ProjectStoreClass, AppState>, id: number) {
    try {
      const res = await Projects.RestoreProject(id);
      if (res.status === 200) {
        await dispatch("GetAllArchivedProjectsFromBackEnd");
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async DeleteProject({ commit }: ActionContext<ProjectStoreClass, AppState>, projectId: string) {
    try {
      const res = await Projects.DeleteProject(projectId);
      if (res === 204) {
        commit("DeleteProject", projectId);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async GetAllUsersFromTheProject({ commit }: ActionContext<ProjectStoreClass, AppState>, projectId: string) {
    const res = await ibomadeUserService.getUserList();
    commit("SetUsersInTheProject", res);
  },
  // Project Sections
  async GetAllSectionFromTheProject({ commit }: ActionContext<ProjectStoreClass, AppState>, projectId: string) {
    try {
      const res = await ProjectSectionDetails.GetAllSectionsInTheProject(projectId);
      if (res.status === 200) {
        commit("SetSectionList", res.data.sections);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data;
    }
  },
  async UpdateSection({ dispatch }: ActionContext<ProjectStoreClass, AppState>, sectionToUpdate: Section) {
    try {
      const res = await ProjectSectionDetails.UpdateSectionDetails(sectionToUpdate);
      if (res.status === 200) {
        dispatch("GetAllSectionFromTheProject", sectionToUpdate.projectId);
        dispatch("DetailsOfProject", sectionToUpdate.projectId);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data;
    }
  },
  async UpdateSectionFromMainProject(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    {
      sectionToUpdate,
      MainProjectId,
      reloadMainProject = true
    }: { sectionToUpdate: Section; MainProjectId: number; reloadMainProject: boolean }
  ) {
    try {
      const res = await ProjectSectionDetails.UpdateSectionDetails(sectionToUpdate);

      reloadMainProject
        ? dispatch("MainProject/ACTION_GET_MAIN_PROJECT", MainProjectId, {
            root: true
          })
        : null;
      return res;
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data;
    }
  },
  async UpdateSectionOrdinalNumber({ dispatch }: ActionContext<ProjectStoreClass, AppState>, sectionToUpdate: Section) {
    try {
      const res = await ProjectSectionDetails.UpdateSectionDetails(sectionToUpdate);
      if (res.status === 200) {
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async GetAllSectionTypesFromTheBackEnd({ commit }: ActionContext<ProjectStoreClass, AppState>) {
    try {
      const res = await ProjectSectionDetails.GetAllSectionTypes();
      commit("SetSectionTypes", res);
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async GetAllSectionSubTypesFromTheBackEnd({ commit }: ActionContext<ProjectStoreClass, AppState>) {
    try {
      const res = await ProjectSectionDetails.GetAllSectionSubTypes();
      commit("SetSectionSubTypes", res);
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async CreateNewSection({ dispatch }: ActionContext<ProjectStoreClass, AppState>, sectionToCreate: Section) {
    try {
      const res = await ProjectSectionDetails.CreateNewSectionInTheProject(sectionToCreate);
      if (res.status === 201) {
        dispatch("GetAllSectionFromTheProject", sectionToCreate.projectId);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.label;
    }
  },
  async GetSectionDetail({ commit }: ActionContext<ProjectStoreClass, AppState>, sectionId: string) {
    try {
      const res = await ProjectSectionDetails.GetSectionDetails(sectionId);
      if (res.status === 200) {
        const currentSectionDetails = new Section(res.data);
        commit("SetSectionDetail", currentSectionDetails);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async DeleteSectionInTheProject({ commit }: ActionContext<ProjectStoreClass, AppState>, sectionId: string) {
    try {
      const res = await ProjectSectionDetails.DeleteSectionInTheProject(sectionId);
      return res;
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async GetCurrentSectionState({ commit }: ActionContext<ProjectStoreClass, AppState>, sectionId: string) {
    try {
      const res = await ProjectSectionDetails.GetSectionState(sectionId);
      if (res.status === 200) {
        const incomingSectionState = new SectionState(res.data);
        commit("SetSectionState", incomingSectionState);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response;
    }
  },
  //GetAllSectionStatus
  async GetAllSectionStatus({ commit }: ActionContext<ProjectStoreClass, AppState>, projectId: string) {
    try {
      const res = await ProjectSectionDetails.GetAllSectionStatus(projectId);
      if (res.status === 200) {
        commit("SetSectionsStateMap", res.data.projectSectionStates);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  //Display types
  async GetAllDisplayTypesFromTheBackEnd({ commit }: ActionContext<ProjectStoreClass, AppState>) {
    try {
      const res = await ProjectSectionDetails.GetAllDisplayTypes();
      commit("SetDisplayTypes", res);
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },

  // VWA Section
  async VWAManualOverrideToggle(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    item: { MOSettings: object; sectionId: string }
  ) {
    try {
      const res = await ProjectSectionOverride.manualOverrideToggleVWA(item.sectionId, item.MOSettings);
      dispatch("GetSectionDetail", item.sectionId);
      return res;
    } catch (error) {
      return error;
    }
  },

  // Section Override
  async CWUManualOverrideToggle(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    item: { MOSettings: object; sectionId: string }
  ) {
    try {
      const res = await ProjectSectionOverride.manualOverrideToggleCWU(item.sectionId, item.MOSettings);
      dispatch("GetSectionDetail", item.sectionId);
      return res;
    } catch (error) {
      return error;
    }
  },
  async SceneManualOverrideToggle(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    item: { MOSettings: object; sectionId: string }
  ) {
    try {
      const res = await ProjectSectionOverride.manualOverrideToggleScene(item.sectionId, item.MOSettings);
      dispatch("GetSectionDetail", item.sectionId);
      return res;
    } catch (error) {
      return error;
    }
  },
  async manualOverrideToggle(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    item: { MOSettings: object; sectionId: string }
  ) {
    try {
      const res = await ProjectSectionOverride.manualOverrideToggle(item.sectionId, item.MOSettings);
      dispatch("GetSectionInterval", item.sectionId);
      return res;
    } catch (error) {
      dispatch("GetSectionInterval", item.sectionId);
      return error;
    }
  },
  // Sections interval
  async GetSectionInterval({ commit }: ActionContext<ProjectStoreClass, AppState>, sectionId: string) {
    try {
      const res = await ProjectSectionOverride.GetSectionIntervalFromTheBackEnd(sectionId);

      commit("SetIntervalList", res.data.displayIntervals);
      return res;
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async CreateNewIntervalInSection(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    item: {
      newIntervalToCreate: Interval;
      sectionId: string;
    }
  ) {
    try {
      const res = (await ProjectSectionOverride.CreateNewIntervalInTheSection(
        item.newIntervalToCreate
      )) as AxiosResponse<any>;
      if (res.status === 200) {
        dispatch("GetSectionInterval", item.sectionId);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async updateInterval({ dispatch }: ActionContext<ProjectStoreClass, AppState>, intervalSectionToUpdate: Interval) {
    try {
      const res = await ProjectSectionOverride.UpdateIntervalInSection(intervalSectionToUpdate);

      dispatch("ProjectProfilesStore/GET_PROFILE_DETAILS", intervalSectionToUpdate.profileId, {
        root: true
      });
      return res;
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async DeleteIntervalInTheSection(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    item: { intervalId: string; sectionId: string }
  ) {
    try {
      const res = await ProjectSectionOverride.DeleteInterval(item.intervalId);

      if (res.status === 200) {
        dispatch("GetSectionInterval", item.sectionId);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async DELETE_iNTERVAL({ dispatch }: ActionContext<ProjectStoreClass, AppState>, intervalId: string) {
    try {
      const res = await ProjectSectionOverride.DeleteInterval(intervalId);
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async GET_INTERVAL_DETAILS({ dispatch }: ActionContext<ProjectStoreClass, AppState>, intervalId: number) {
    try {
      const res = await ProjectSectionOverride.GetIntervalDetails(intervalId);
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async GetManualOverrideSingOptions({ commit }: ActionContext<ProjectStoreClass, AppState>, sectionId: string) {
    try {
      const res = await ProjectSectionOverride.ManualOverrideSingOptions(sectionId);

      commit("SetManualOverrideSignOptions", res);
      return res;
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },

  /*----------  fpu settings  ----------*/
  async SetFPUSettings({ dispatch }: ActionContext<ProjectStoreClass, AppState>, item: { value: object; id: number }) {
    try {
      const res = await ProjectSectionDisplays.UpdateFPUSettings(item.value, item.id);
      dispatch("GetCurrentSectionState", item.id);
      return res;
    } catch (error) {
      return error;
    }
  },
  async GetDisplayDetails({ commit }: ActionContext<ProjectStoreClass, AppState>, displayId: number) {
    try {
      const res = await ProjectSectionDisplays.GetDisplayDetails(displayId);
      commit("SetDisplayInformation", res.data);
      return res;
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  // Project Update Threshold
  async UpdateThreshold(
    { commit }: ActionContext<ProjectStoreClass, AppState>,
    item: { id: string; thresholdToUpdate: object }
  ) {
    try {
      const res = await ProjectSectionOverride.UpdateThreshold(item.id, item.thresholdToUpdate);
      return res;
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async GetCurrentThreshold({ commit }: ActionContext<ProjectStoreClass, AppState>, projectId: string) {
    try {
      const res = await ProjectSectionOverride.GetCurrentThreshold(projectId);
      commit("SetCurrentThreshold", new ProjectThreshold(res.data));
      return res;
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async getCurrentThresholdTable({ commit }: ActionContext<ProjectStoreClass, AppState>, projectId: string) {
    try {
      const res = await ProjectSectionOverride.getThresholdTable(projectId);
      commit("SetCurrentThresholdTable", res.data.data);
      return res;
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  //Get camera section latest picture from the backend
  async GetCameraSectionLatestPicture(
    { commit }: ActionContext<ProjectStoreClass, AppState>,
    cameraIdentifier: string
  ) {
    try {
      const res = (await ProjectSectionOverride.GetLatestPictureCameraSection(cameraIdentifier)) as AxiosResponse<any>;
      if (res.status === 200) {
        commit("SetCameraSectionLatestPicture", res.data);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async GetCameraSectionIdentifier({ commit }: ActionContext<ProjectStoreClass, AppState>, sectionId: string) {
    try {
      const res = await ProjectSectionOverride.GetCameraSectionSetting(sectionId);
      commit("SetCameraSectionSettings", res.data);

      return res;
    } catch (error) {
      const err: AxiosError<ResponseError> = error as AxiosError;
      return err.response?.data.message;
    }
  },
  async UpdateCameraIdentifier(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    { sectionId, cameraSettings }: { sectionId: number; cameraSettings: CameraSettings }
  ) {
    try {
      const res = await ProjectSectionOverride.UpdateCameraSectionSetting(sectionId, cameraSettings);
      if (res.status === 200) {
        dispatch("GetCameraSectionIdentifier", sectionId);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },
  updateMainProjectOpen(
    { commit }: ActionContext<ProjectStoreClass, AppState>,
    currentMainProjectOpen: CurrentRowOpen
  ) {
    commit("SetCurrentRowOpenMainProjectInTheProjectList", currentMainProjectOpen);
  },
  updateSubProjectOpen({ commit }: ActionContext<ProjectStoreClass, AppState>, updateSubProjectOpen: CurrentRowOpen) {
    commit("SetCurrentRowOpenInTheProjectList", updateSubProjectOpen);
  },
  /*----------  Country and state  ----------*/
  async GetCountryList({ commit }: ActionContext<ProjectStoreClass, AppState>) {
    try {
      const res = await CountryService.GetCountryList();
      if (res.status === 200) {
        commit("SetCountryList", res.data);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  },
  async GetTrafficCounter(
    { commit }: ActionContext<ProjectStoreClass, AppState>,
    { sectionId, projectVolume }: { sectionId: string; projectVolume: string }
  ) {
    try {
      const res = await TrafficCountDetails.GetTrafficCount(sectionId, projectVolume);
      if (res.status === 200) {
        const trafficStats = new TrafficCountStats(res.data);
        commit("SetSectionTrafficStats", trafficStats);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },
  async ResetTrafficCounting(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    { id, from, to, projectVolume }: { id: string; from: Date; to: Date; projectVolume: string }
  ) {
    try {
      console.log(id);
      const res = await ProjectSectionDetails.ResetTrafficCount(id, from, to);
      if (res.status === 200) {
        dispatch("GetTrafficCounter", { sectionId: id, projectVolume });
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },
  async SetMeasureAndCameraSectionSettings(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    {
      sectionId,
      sectionTypeApi,
      measureCameraSectionSettings
    }: { sectionId: number; sectionTypeApi: string; measureCameraSectionSettings: object }
  ) {
    try {
      const res = await ProjectSectionDetails.SetMeasureAndCameraSectionSettings(
        sectionId,
        sectionTypeApi,
        measureCameraSectionSettings
      );
      if (res.status === 200) {
        dispatch("GetSectionDetail", sectionId);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },
  async SetCwuCustomFilenameSettings(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    { sectionId, cwuCustomFilenameSettings }: { sectionId: number; cwuCustomFilenameSettings: object }
  ) {
    try {
      const res = await ProjectSectionDetails.SetCwuCustomFilenameSettings(sectionId, cwuCustomFilenameSettings);
      if (res.status === 200) {
        dispatch("GetSectionDetail", sectionId);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },
  async SetSectionDeviceState(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    { sectionId, sectionState }: { sectionId: number; sectionState: object }
  ) {
    try {
      const res = await ProjectSectionDetails.SetSectionDeviceState(sectionId, sectionState);
      if (res.status === 200) {
        dispatch("GetSectionDetail", sectionId);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },
  async GetActiveSceneForSceneBasedProject({ commit }: ActionContext<ProjectStoreClass, AppState>, projectId: number) {
    try {
      const res = await SceneProject.GetActiveScene(projectId);
      if (res.status !== 200) return res;

      commit("SetActiveScene", res.data.scene);
      return res;
    } catch (error) {
      return error;
    }
  },
  async SceneProjectSwitch(
    { dispatch }: ActionContext<ProjectStoreClass, AppState>,
    { projectId, sceneId }: { projectId: number; sceneId: number }
  ) {
    try {
      const res = await SceneProject.SwitchScene(projectId, sceneId);
      if (res.status === 200) {
        return res;
      }
    } catch (error) {
      return error;
    }
  }
};
